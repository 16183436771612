<template>
    <page-title-emit @previous="$emit('previous')" text='Confirm OTP'/>
    <div class="booking-notice">
        <div class="flex-row align-start">
            <p class="message">Please enter the 4-digit code sent the customer’s phone number and email address</p>
        </div>
    </div>

    <div>
        <form @submit.prevent="submit" >
            <div class="flex-row-space-between otp-inputs">
                <input
                    type="text"
                    maxlength="1"
                    class="form-control booking-option-input otp-input"
                    id="one"
                    aria-describedby="emailHelp"
                    @keyup="enterCode($event,'none','one','two')"
                >
                <input
                    type="text"
                    maxlength="1"
                    class="form-control booking-option-input otp-input"
                    id="two"
                    aria-describedby="emailHelp"
                    @keyup="enterCode($event,'one','two','three')"
                >
                <input
                    type="text"
                    maxlength="1"
                    class="form-control booking-option-input otp-input"
                    id="three"
                    aria-describedby="emailHelp"
                    @keyup="enterCode($event,'two','three','four')"
                >
                <input
                    type="text"
                    maxlength="1"
                    class="form-control booking-option-input otp-input"
                    id="four"
                    aria-describedby="emailHelp"
                    @keyup="enterCode($event,'three','four','done')"
                >

            </div>

    <div class="resend-code-box">
        <p class="no-code">Didn't receive code?</p>
        <p class="no-code resend-code">
            <countdown-timer
                value="120"
                :should-start="true"
                @on-timer-restart="initiateOtp"
            />
        </p>
    </div>

    <button
        @click="onSubmit"
        type="submit"
        :class="isFormComplete ? 'black-button': 'disabled-button'"
        :disabled="!isFormComplete"
        class="booking-btn"
    >
        <span v-if="!loading">
            <span>Proceed</span>
        </span>
        <span v-else class="spinner-border text-light" role="status">
        </span>
    </button>

     <p class="forgot-password-link">
            <router-link to="/home">Go Home</router-link>
    </p>

</form>

    <!-- Modal -->
    <Modal :open="isOpen" @close="isOpen = !isOpen">
        <div style="text-align: center;">
            <img v-if="!booked" class="bus-icon" src='@/assets/icons/bus-icon-sufficient.svg'/>
            <img v-else class="bus-icon" src='@/assets/icons/bus-icon-insufficient.svg'/>

            <p v-if="!booked" class="no-show-question">Booking Successful</p>
            <p v-else class="no-show-question">Insufficient Balance</p>

            <p v-if="!booked" class="no-show-description">
                You have successfully booked for <span style="font-weight: 500;">{{getBookingUserDetails?.fname}} {{getBookingUserDetails?.lname}}</span>   on this trip.
            </p>
            <p v-else class="no-show-description">
                {{getBookingUserDetails?.fname}} {{getBookingUserDetails?.lname}}
                does not have enough funds in the selected wallet to perform this transaction.
            </p>

            <button
                @click="redirectToTripDetailsPage"
                type="submit"
                class="green-button to-trip-manifest"
            >
                <span v-if="!loading">
                    <span>View Trip Manifest</span>
                </span>
                <span v-else class="spinner-border text-light" role="status">
                </span>
            </button>
        </div>
    </Modal>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import CountdownTimer from '@/components/CountdownTimer'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
import PageTitleEmit from '@/components/PageTitleEmit.vue'
import Modal from '@/components/Modal.vue'
import axios from 'axios'
import { ref } from 'vue'
import authHeader from '@/services/auth-header'
import moment from 'moment'
import FeatureFlagService, { FEATURE_FLAGS } from '@/services/feature-flags'
// import CountdownTimer from '@/components/CountdownTimer.vue';
export default {
  name: 'OtpPage',
  setup () {
    const isOpen = ref(false)

    return { isOpen }
  },
  emits: [
    'correct-otp-collected',
    'user-info-collected',
    'previous',
    'paying-wallet-selected',
    'wallet-type-selected',
    'guest-info-collected',
    'user-is-guest',
    'pickup-dropoff-selected'
  ],
  props: {
    currentStep: Number
  },
  data () {
    return {
      form: {
        value: ''
      },
      booked: false,
      ref: '',
      isFormComplete: false,
      showError: false,
      loading: false,
      showPassword: false,
      is_email_login: true,
      start_timer: false
    }
  },
  computed: {
    ...mapGetters([
      'getTripToBook',
      'getBookingUserDetails',
      'getPayingUser',
      'getPayingWallet',
      'getPaymentSource',
      'getBookingReference',
      'pickup-dropoff-selected',
      'getSomeonePickUpId',
      'getSomeoneDestinationId'
    ])
  },
  created () {
    this.start_timer = true
  },
  methods: {
    ...mapActions(['setUserToBook', 'user-info-collected', 'setBookingReference']),
    backToPassengerDetails () {
      this.$emit('correct-otp-collected')
    },
    stayOnPagge () {
      this.$emit('user-info-collected')
    },
    enterCode (event, previous, current, next) {
      if (event.key !== 'Backspace') {
        if (next !== 'done') {
          this.form.value += document.getElementById(current).value.replace(/[^0-9]/g, '')
          document.getElementById(next).focus()
        } else if (next === 'done') {
          this.isFormComplete = true
          this.form.value += document.getElementById(current).value.replace(/[^0-9]/g, '')
        }
      } else {
        if (previous !== 'none' && document.getElementById(current).value === '') {
          document.getElementById('one').value = ''
          document.getElementById('two').value = ''
          document.getElementById('three').value = ''
          document.getElementById('four').value = ''
          document.getElementById('one').focus()
          this.form.value = ''
        }
      }
    },
    async onSubmit () {
      this.loading = true
      try {
        // add reference_id to the booking payload
        let payload = {
          // payment_reference : "",
          payment_source: this.getPaymentSource,
          paid_by: this.getPayingUser,
          otp_code: this.form.value,
          reference_id: this.getBookingReference,

          route_id: this.getTripToBook.route_id,
          itinerary_id: this.getTripToBook.itinerary.id,
          meta: this.getTripToBook.meta,

          pickup_id: this.getSomeonePickUpId,
          destination_id: this.getSomeoneDestinationId,

          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),

          day_id: this.getTripToBook.day_id,
          days_ids: [this.getTripToBook.day_id],
          route_day_id: this.getTripToBook.route_day_id,
          luggage_quantity: this.getTripToBook.luggage_quantity,

          driver_id: this.getTripToBook.driver.id,
          vehicle_id: this.getTripToBook.vehicle.id,
          recurring: false
        }
        const isNewDRPEnabled = FeatureFlagService.isFlagEnabled(FEATURE_FLAGS.DRP_CLIENT);
        if (isNewDRPEnabled) {
          payload = { ...payload, differential_price: this.getTripToBook.fare }
        }
        await axios.post(
          `/bus-captains/users/${this.getBookingUserDetails?.id}/routes`,
          payload,
          { headers: authHeader() }
        );
        this.isOpen = !this.isOpen
      } catch (error) {
        this.$toast.warning(error.response?.data.message || 'An error occurred')
      } finally {
        this.loading = false
      }
    },
    clearOTPEntry () {
      const entry_ids = ['one', 'two', 'three', 'four']
      for (let index = 0; index < entry_ids.length; index++) {
        const element = entry_ids[index]
        document.getElementById(element).value = ''
      }
    },
    redirectToApphome () {
      router.push({ path: '/home' })
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },
    redirectToTripDetailsPage () {
      if (!this.getTripToBook.trip_id) {
        router.push({
          name: 'TripDetail',
          params: {
            tripId: `${this.getTripToBook.id}`,
            routeId: `${this.getTripToBook.route_id}`,
            tripDate: `${this.getTripToBook.trip_date}`,
            driverId: `${this.getTripToBook.driver.id}`
          }
        })
      } else {
        router.push({
          name: 'ActiveTripPage',
          params: {
            routeId: `${this.getTripToBook.route_id}`,
            tripDate: `${this.getTripToBook.trip_date}`,
            driverId: `${this.getTripToBook.driver.id}`
          }
        })
      }
    },
    async initiateOtp () {
      try {
        const response = await axios.post(
                    `/bus-captains/users/${this.getBookingUserDetails?.id}/otps`,
                    {},
                    { headers: authHeader() }
        )
        this.setBookingReference(response.data.reference_id)
        this.loading = false
        this.$toast.success('OTP sent successfully')
      } catch (error) {
        this.$toast.warning(error.response?.data.message)
        this.loading = false
      }
    }
  },
  components: {
    Header,
    PageTitleEmit,
    Modal,
    CountdownTimer
    // CountdownTimer
  }
}
</script>
<style lang="scss" scoped>
    .otp-input{
        width: 71px;
        height: 71px;
        background: $sh-neutral-100;
        text-align: center;
    }
    .otp-inputs{
        margin-top: 32px;
    }
    .no-code{
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 24px;
        text-align: center;
        color: #6E717C;
        margin: 0;
    }
    .resend-code{
        margin: 0;
        color: $sh-neutral-900;
    }
    .count-down{
        color: $sh-green-700;
    }
    .resend-code-box{
        margin-top: 46px;
    }
</style>s
