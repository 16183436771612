import trips from './modules/trips'
import auth from './modules/auth'
import booking from './modules/booking'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import ping from '@/store/modules/ping';

const store = createStore({
  plugins: [createPersistedState({
    paths: ['trips']
  })],
  modules: {
    trips,
    auth,
    booking,
    ping
  }
})

export default store
