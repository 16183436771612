import axios from 'axios'
import authHeader from '@/services/auth-header'
import 'core-js/actual/array/group-by'

const state = {
  booking_user_details: {},
  guest_user_details: {},
  paying_user: '',
  paying_wallet: '',
  payment_source: '',
  booking_error: '',
  trip_to_book: {},
  booking_details: {
    reference_id: ''
  },
  booking_reference: '',
  someonePickUpId: '',
  someoneDestinationId: ''
}

const getters = {
  getBookingUserDetails: state => state.booking_user_details,
  getGuestUserDetails: state => state.guest_user_details,
  getPayingUser: state => state.paying_user,
  getPayingWallet: state => state.paying_wallet,
  getTripToBook: state => state.trip_to_book,
  getBookingDetails: state => state.booking_details,
  getBookingReference: state => state.booking_reference,
  getPaymentSource: state => state.payment_source,
  getSomeonePickUpId: state => state.someonePickUpId,
  getSomeoneDestinationId: state => state.someoneDestinationId
}

const actions = {
  setBookingUserDetails ({ commit }, booking_user_details) {
    commit('setBookingUserDetails', booking_user_details)
  },
  setPayingUser ({ commit }, paying_user) {
    commit('setPayingUser', paying_user)
  },
  setTripToBook ({ commit }, trip_to_book) {
    commit('setTripToBook', trip_to_book)
  },
  setPayingWallet ({ commit }, wallet) {
    commit('setPayingWallet', wallet)
  },
  setBookingDetails ({ commit }, booking_details) {
    commit('setBookingDetails', booking_details)
  },
  setBookingReference ({ commit }, payload) {
    commit('setBookingReference', payload)
  },
  setPaymentSource ({ commit }, payment_source) {
    commit('setPaymentSource', payment_source)
  },
  setSomeonePickUpId ({ commit }, someonePickUpId) {
    commit('setSomeonePickUpId', someonePickUpId)
  },
  setSomeoneDestinationId ({ commit }, someoneDestinationId) {
    commit('setSomeoneDestinationId', someoneDestinationId)
  }
}

const mutations = {
  setBookingUserDetails: (state, booking_user_details) => state.booking_user_details = booking_user_details,
  setBookingError: (state, error_object) => state.booking_error = error_object,
  setPayingUser: (state, paying_user) => state.paying_user = paying_user,
  setTripToBook: (state, trip_to_book) => state.trip_to_book = trip_to_book,
  setPayingWallet: (state, wallet) => state.paying_wallet = wallet,
  setBookingDetails: (state, booking_details) => state.booking_details = { ...booking_details },
  setBookingReference: (state, reference) => state.booking_reference = reference,
  setPaymentSource: (state, payment_source) => state.payment_source = payment_source,
  setSomeonePickUpId: (state, someonePickUpId) => state.someonePickUpId = someonePickUpId,
  setSomeoneDestinationId: (state, someoneDestinationId) => state.someoneDestinationId = someoneDestinationId
}

export default {
  state,
  getters,
  actions,
  mutations
}
